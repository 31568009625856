import { GridPhotoWrapper } from '@/components/PostWrapper';
import { IPostWrapper } from '@/interfaces/wrapper';
import React from 'react';
import { LatestNews } from '.';

const HomeTopSectionL = ({ data }: IPostWrapper) => {
  return (
    <div>
      <LatestNews />

      <GridPhotoWrapper data={data} />
    </div>
  );
};

export default HomeTopSectionL;
